/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes, { bool } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Image } from 'react-bootstrap';
import { TableContext } from '../../contexts/tableContext';
// import { SpinnerRoundFilled } from 'spinners-react';
import ActionButtons from '../ActionsButtons/ActionButtons';
import Pagination from '../Pagination/Pagination';
import { tabledelete } from '../../redux/TableDeleteSlice';
import ToggleButtonOnOff from '../ToggleButton/ToggleButton';
// import ActionSelection from '../ActionsButtons/ActionSelection';
import styles from './CustomTable.module.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getCookies } from '../../hooks/useCookies';
import { DateFormatter } from '../../utilityFunctions/utilsFunctions';
import {
  FTD_DETAIL_LINK,
  REACT_APP_API_REVIEW_PUBLISH,
  SERVER_BASE_URL,
} from '../../redux/apiConstants';
import Approve from '../Approve/Approve';
import ClaimApprove from '../ClaimRequsetApproveBtn/ClaimAccept';
import ActionButtons1 from '../ActionsButtons/ActionButton1';
import SubjectCategoryActionButtons from '../ActionsButtons/SubjectCategoryActionButtons';

const dummyImage = require('../../assets/no_image.png');

const CustomTable = React.memo(
  ({ urlParam, viewPopUp, toggleButton, section, deleteCondition }) => {
    let url;
    const { dashboardStyle } = useContext(TableContext);
    const navigate = useNavigate();
    const { bodyStyle } = useContext(TableContext);
    const [addButtonPermissions, setAddButtonPermissions] = useState({});
    const { bodyheader } = useContext(TableContext);
    const { dateRanges } = useContext(TableContext);
    const { setDashboardHeader } = useContext(TableContext);
    const { searchboxStyle } = useContext(TableContext);
    const { tableheadStyle } = useContext(TableContext);
    const { tablebodyStyle } = useContext(TableContext);
    const { deletedData } = useContext(TableContext);
    const { approve, setApprove } = useContext(TableContext);
    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch();
    const { pageNum, setPageNum } = useContext(TableContext);
    // new
    const tablelength = tableData.length;
    const [checkedState, setCheckedState] = useState(new Array(tablelength).fill(false));
    const [checkedAll, setCheckedAll] = useState(false);
    const token = `Bearer ${getCookies('Token')}`;
    const [sorting, setSorting] = useState([{ col: 'none', sortType: 'none' }]);
    const [paginationData, setPaginationData] = useState([
      { page: 0, totalResult: 0, totalPages: 0 },
    ]);
    const [search, setSearchTerm] = useState(null);
    const [timer, setTimer] = useState(null);
    const [columns, setColumns] = useState([]);
    const [deletedDataa, setDeletedData] = useState(null);
    const [selected, setselected] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [canPublish, setCanPublish] = useState(true);
    const dataPerPage = 5;
    let response;
    let statusCode;
    const defaultProfilePic =
      'https://boilerplate-armia.s3.us-west-2.amazonaws.com/downloads/blank-profile-picture-973460_960_720.webp-1671689291091';
    const handleOnChange = (id, position) => {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len
      const updatedCheckedState = checkedState.map((item, indexed) =>
        indexed === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
      setselected((oldArray) => [...oldArray, id]);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= checkedState.length; i++) {
        if (checkedState[position] === true) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < selected.length; j++) {
            if (selected[j] === id) {
              selected.splice(j, 1);
            }
            // const newselected = selected.filter((item) => item === id);
            // setselected(newselected);
          }
        }
      }
    };
    const actionAdd = () => {
      navigate(`/${section.toLowerCase()}/create`);
    };
    const handleOnChangeAll = () => {
      setCheckedAll(!checkedAll);
      const newarray = [];
      if (checkedAll === false) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    };
    const getUsers = async () => {
      setTableLoading(true);
      url = urlParam;
      url += `page=${pageNum}&limit=${dataPerPage}`;
      if (sorting.sortType && sorting.sortType !== 'none') {
        url += `&sortBy=${sorting.col}:${sorting.sortType}`;
      }
      if (search !== null) {
        url += `&searchBy=${search}`;
      }
      if (dateRanges !== null) {
        url += `&daterange=${encodeURIComponent(dateRanges)}`;
      }
      axios
        .get(url, {
          method: 'GET',
          headers: { Authorization: token },
        })
        .then((resp) => {
          setTableLoading(false);
          statusCode = resp.status;
          return resp;
        })
        .then((res) => {
          if (statusCode === 200) {
            response = res.data.data.result;
            setTableData(response.results);
            setPaginationData({
              page: response.page,
              totalResult: response.totalResults,
              totalPages: response.totalPages,
            });
            setColumns(response.Displayfields);
          }
        })
        .catch((err) => {
          setTableLoading(false);
          return err;
        });
    };

    useEffect(() => {
      setSearchTerm(null);
      setPageNum(1);
    }, []);

    useEffect(() => {
      // setDashboardHeader(section);
      const temp = section.replace(/\s+/g, '').toLowerCase();
      const permissions = getCookies('USERPERMISSION');
      permissions.forEach((val) => {
        if (val.section.toLowerCase() === temp) {
          setAddButtonPermissions({
            view: val.view ? val.view : false,
            edit: val.edit ? val.edit : false,
            create: val.create ? val.create : false,
            delete: val.delete ? val.delete : false,
            list: !!(val.view || val.edit || val.create || val.delete),
          });
        } else if (val.submenu) {
          val.submenu.forEach((element) => {
            if (element.section.toLowerCase() === temp) {
              const addbutpref = {
                view: element.view ? element.view : false,
                edit: element.edit ? element.edit : false,
                create: element.create ? element.create : false,
                delete: element.delete ? element.delete : false,
                list: !!(element.view || element.edit || element.create || element.delete),
              };
              setAddButtonPermissions(addbutpref);
            }
          });
        }
      });
      getUsers();
      const newarray = [];
      if (checkedAll) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    }, [pageNum, search, sorting, deletedData, dateRanges, approve]);
    const setSort = (param) => () => {
      setPageNum(1);
      switch (sorting.sortType) {
        case 'none':
          setSorting({ col: param, sortType: 'asc' });
          break;
        case 'asc':
          setSorting({ col: param, sortType: 'desc' });
          break;
        case 'desc':
          setSorting({ col: param, sortType: 'none' });
          break;
        default:
          setSorting({ col: param, sortType: 'asc' });
          break;
      }
    };
    const onFilterTextChange = (e) => {
      const even = e;
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          const { value } = even.target;
          if (value.length > 0) {
            setPageNum(1);
            setSearchTerm(even.target.value);
          } else {
            setSearchTerm(null);
          }
        }, 1000)
      );
    };

    const publishReview = (params) => {
      confirmAlert({
        title: canPublish ? '' : 'Oops!!',
        message: 'Are you sure you want to publish',
        buttons: canPublish
          ? [
              {
                label: 'yes',
                onClick: async () => {
                  const publishUrl = `${REACT_APP_API_REVIEW_PUBLISH}/${params}`;
                  try {
                    const headers = {
                      'Access-Control-Allow-Origin': '*',
                      Authorization: `Bearer ${getCookies('Token')}`,
                    };
                    const publish = await axios.patch(publishUrl, { headers });
                    toast.success('Published successfully');
                    setApprove(`data last published is ${params}`);
                  } catch (error) {
                    console.log(error);
                  }
                },
              },
              {
                label: 'No',
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: 'Ok',
                // onClick: () => alert("Click No")
              },
            ],
      });
    };
    return (
      <div className={styles[bodyStyle]}>
        {addButtonPermissions.list && (
          <div
            className={styles[dashboardStyle]}
            style={{ minHeight: '100vh' }}
            id={styles.dashboardcont2}
          >
            <div className="container-fluid" id={styles.container}>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className={styles.search_box}>
                    <input
                      className={styles[searchboxStyle]}
                      id={styles.searchbar}
                      type="search"
                      onChange={onFilterTextChange}
                      placeholder="Search"
                      // value={search}
                      readOnly={tableLoading}
                    />
                    <div
                      className={tableLoading ? 'spinner-border spinner-border-sm' : ''}
                      id={styles.spinner}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                  {/* <h5 className={styles[bodyheader]} id={styles.tableheading}>
                  {section} Data
                </h5> */}
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12" />
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className={styles.sectionright}>
                    {/* {addButtonPermissions.delete && selected.length > 0 && (
                      <div className="dropdown" id={styles.dropdown}>
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <ul
                          className="dropdown-menu"
                          id={styles.dropdown_menu}
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                confirmAlert({
                                  title: '',
                                  message: 'Are you sure, you want to delete.',
                                  buttons: [
                                    {
                                      label: 'Yes',
                                      onClick: () => {
                                        dispatch(tabledelete(selected)).then(() => {
                                          setDeletedData(`data deleted ${selected}`);
                                          window.location.reload(false);
                                        });
                                      },
                                    },
                                    {
                                      label: 'No',
                                      // onClick: () => alert("Click No")
                                    },
                                  ],
                                });
                              }}
                            >
                              Delete{' '}
                              <i className="fa fa-trash" aria-hidden="true" id={styles.del_icon} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    )} */}
                    {/* {addButtonPermissions.delete && (
                    <button
                      className={selected.length ? styles.tabledelbtn : 'disabled'}
                      type="button"
                      onClick={() => {
                        confirmAlert({
                          title: '',
                          message: 'Are you sure want to delete.',
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                dispatch(tabledelete(selected)).then(() => {
                                  setDeletedData(`data deleted ${selected}`);
                                  window.location.reload(false);
                                });
                              },
                            },
                            {
                              label: 'No',
                              // onClick: () => alert("Click No")
                            },
                          ],
                        });
                      }}
                    >
                      <i className="fa fa-trash" aria-hidden="true" id={styles.del_icon} />
                    </button>
                  )} */}
                    {addButtonPermissions.create && (
                      <button
                        className={styles.tablebtn}
                        type="button"
                        onClick={() => {
                          actionAdd();
                        }}
                      >
                        {' '}
                        <FontAwesomeIcon icon={faPlus} className={styles.add_icon} />
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="table-responsive" style={{ minHeight: '250px' }}>
                <table className="table table-borderless">
                  {columns.length > 0 && (
                    <thead>
                      <tr className={styles[tableheadStyle]}>
                        {/* {addButtonPermissions.delete && (
                          <th scope="col">
                            <input
                              type="checkbox"
                              id="custom-checkbox"
                              // name={data.id}
                              // value={data.id}
                              checked={checkedAll}
                              onChange={() => handleOnChangeAll()}
                            />
                          </th>
                        )} */}
                        {columns.map((item) => (
                          <th scope="col">
                            {' '}
                            {section === 'businesspage' && item === 'description'
                              ? (item = 'Overview')
                              : section === 'normaluser' && item === 'image'
                              ? (item = 'PROFILE PIC')
                              : item === 'businessUserName'
                              ? (item = 'BUSINESS USER NAME')
                              : item === 'businessUserEmail'
                              ? (item = 'BUSINESS USER EMAIL')
                              : item === 'companyName'
                              ? (item = 'COMPANY NAME')
                              : item === 'normalusertype'
                              ? (item = 'NORMAL USER TYPE')
                              : item === 'jobrole'
                              ? (item = 'JOB ROLE')
                              : item === 'companyname'
                              ? (item = 'COMPANY NAME')
                              : item === 'businessName'
                              ? (item = 'BUSINESS NAME')
                              : item === 'userName'
                              ? (item = 'USER NAME')
                              : item === 'username'
                              ? (item = 'USER NAME')
                              : item === 'claimApprove'
                              ? (item = 'CLAIM APPROVE')
                              : item === 'location_name'
                              ? (item = 'LOCATION')
                              : item === 'isClaimed'
                              ? (item = 'STATUS')
                              : item === 'adminCreated'
                              ? (item = 'CREATED BY')
                              : item === 'isSubscribed'
                              ? (item = 'SUBSCRIBED')
                              : item === 'business_phone'
                              ? (item = 'PHONE')
                              : item === 'business_email'
                              ? (item = 'EMAIL')
                              : item.toUpperCase()}
                            {item !== 'image' && item !== 'PROFILE PIC' && item !== 'proof' && (
                              <button
                                className={styles.sortbtn}
                                type="button"
                                aria-label="Sort button"
                                onClick={setSort(item)}
                              >
                                {sorting.sortType === 'asc' && sorting.col === item ? (
                                  <i className="fa fa-arrow-up" />
                                ) : sorting.sortType === 'desc' && sorting.col === item ? (
                                  <i className="fa fa-arrow-down" />
                                ) : (
                                  <i className="fa fa-sort" />
                                )}
                              </button>
                            )}
                          </th>
                        ))}
                        {section !== 'claimrequests' &&
                          section !== 'publishrequests' &&
                          section !== 'support' &&
                          section !== 'contactsupport' && <th scope="col">ACTIONS</th>}
                      </tr>
                    </thead>
                  )}
                  {Object.keys(tableData) && !tableLoading ? (
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr className={styles[tablebodyStyle]}>
                          {console.log(item, 'item')}
                          {/* {addButtonPermissions.delete && (
                            <th scope="row">
                              <div className="left-section">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${item}`}
                                  name={item.id}
                                  value={item.id}
                                  checked={checkedState[index]}
                                  onChange={() => handleOnChange(item.id, index)}
                                />
                              </div>
                            </th>
                          )} */}
                          {columns.map((ele) => (
                            <td>
                              {section === 'normaluser' && ele === 'image' ? (
                                <Image
                                  src={item.image ? item.image : defaultProfilePic}
                                  style={{ width: '50px', height: '40px' }}
                                  alt="profile pic"
                                />
                              ) : section === 'subjectcategory' && ele === 'image' ? (
                                <Image
                                  src={item.image}
                                  style={{ width: '50px', height: '40px' }}
                                  alt="img"
                                />
                              ) : ele === 'proof' ? (
                                <Image
                                  src={item.proof[0]}
                                  style={{ width: '50px', height: '40px' }}
                                  alt="proof"
                                />
                              ) : ele === 'userName' ? (
                                <a
                                  target="_blank"
                                  href={`${FTD_DETAIL_LINK}/adminlogin?token=${getCookies(
                                    'refreshToken'
                                  )}&uid=${item?.userId?._id}&utp=normal`}
                                  rel="noreferrer"
                                >
                                  {item.userName}
                                </a>
                              ) : section === 'publishrequests' && ele === 'name' ? (
                                <a
                                  target="_blank"
                                  href={`${FTD_DETAIL_LINK}/adminlogin?token=${getCookies(
                                    'refreshToken'
                                  )}&uid=${item?.user?._id}&utp=business`}
                                  rel="noreferrer"
                                >
                                  {item.name}
                                </a>
                              ) : section === 'businesspage' && ele === 'name' ? (
                                <a
                                  target="_blank"
                                  href={`${FTD_DETAIL_LINK}/adminlogin?token=${getCookies(
                                    'refreshToken'
                                  )}&uid=${item.user}&bid=${item?.id}&utp=business`}
                                  rel="noreferrer"
                                >
                                  {item.name}
                                </a>
                              ) : section === 'businesspage' && ele === 'images' ? (
                                <Image
                                  src={item.images.length > 0 ? item.images[0] : dummyImage}
                                  style={{ width: '80px', height: '80px' }}
                                  alt="ftd pic"
                                />
                              ) : section === 'businesspage' && ele === 'adminCreated' ? (
                                <p>{item.adminCreated === true ? 'ADMIN' : 'USER'}</p>
                              ) : section === 'businesspage' && ele === 'isSubscribed' ? (
                                <p>{item.isSubscribed === true ? 'Yes' : 'No'}</p>
                              ) : section === 'businesspage' && ele === 'location_name' ? (
                                <p style={{ width: '100px' }}>{item.location_name}</p>
                              ) : section === 'claimrequests' && ele === 'FTD' ? (
                                <a>{item.FTD}</a>
                              ) : ele === 'businessName' ? (
                                <a
                                  target="_blank"
                                  href={`${FTD_DETAIL_LINK}/adminlogin?token=${getCookies(
                                    'refreshToken'
                                  )}&uid=${item?.userId?._id}&bid=${item.business._id}&utp=normal`}
                                  rel="noreferrer"
                                >
                                  {item.businessName}
                                </a>
                              ) : ele === 'isClaimed' ? (
                                <p>
                                  {item.adminCreated === true && item.isClaimed === true
                                    ? 'Claimed'
                                    : item.adminCreated === true && item.isClaimed === false
                                    ? 'UnClaimed'
                                    : 'N/A'}
                                </p>
                              ) : ele === toggleButton.field ? (
                                <ToggleButtonOnOff
                                  value={item[ele]}
                                  id={item.id}
                                  endPoint={toggleButton.url || null}
                                  placeholders={['Active', 'Inactive']}
                                />
                              ) : ele === 'publish' ? (
                                <button
                                  className="bg-success border-0 text-white"
                                  onClick={() => publishReview(item.id)}
                                  type="button"
                                >
                                  Publish
                                </button>
                              ) : ele === 'approve' ? (
                                <Approve data={item} />
                              ) : ele === 'claimApprove' ? (
                                <ClaimApprove data={item} />
                              ) : ele === 'createdAt' ? (
                                DateFormatter(item.createdAt, 'toLocaleStringWithoutTimeSeconds')
                              ) : typeof item[ele] === 'boolean' ? (
                                <h6 className="order_date"> String(item[ele])</h6>
                              ) : (
                                item[ele]
                              )}
                            </td>
                          ))}
                          {section !== 'claimrequests' &&
                            section !== 'publishrequests' &&
                            section !== 'support' &&
                            section !== 'contactsupport' && (
                              <td>
                                {section === 'subjectcategory' ? (
                                  <SubjectCategoryActionButtons
                                    data={item}
                                    viewPopUp={viewPopUp}
                                    section={section}
                                  />
                                ) : section === 'businesspage' ? (
                                  <ActionButtons1
                                    data={item}
                                    viewPopUp={viewPopUp}
                                    section={section}
                                  />
                                ) : (
                                  <ActionButtons
                                    data={item}
                                    viewPopUp={viewPopUp}
                                    section={section}
                                  />
                                )}
                              </td>
                            )}
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <h6>Loading...</h6>
                  )}
                </table>
                {tableData.length === 0 && !tableLoading ? (
                  <div className="col-md-12">
                    <h6 className={styles.norecord}>No record found</h6>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <Pagination paginationData={paginationData} setPageNum={setPageNum} />
              <br />
              <br />
            </div>
          </div>
        )}
      </div>
    );
  }
);
CustomTable.propTypes = {
  urlParam: PropTypes.string,
  viewPopUp: PropTypes.bool,
  toggleButton: PropTypes.oneOfType([PropTypes.object]),
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  section: PropTypes.string,
};
CustomTable.defaultProps = {
  viewPopUp: false,
  toggleButton: { show: false, field: 'none', url: null },
  deleteCondition: { checkCondition: false, apiUrl: 'none' },
  section: 'User',
  urlParam: null,
};
export default CustomTable;
