import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SpinnerDotted } from 'spinners-react';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { Entry } from '../../redux/entrySlice';
import styles from './editBusinessPage.module.scss';
import { TableContext } from '../../contexts/tableContext';
import 'react-toastify/dist/ReactToastify.css';
import TextInput from '../../components/TextField/TextField';
import SelectBox from '../../components/SelectBox/SelectBox';
import TextAreaField from '../../components/TextArea/TextArea';
import { REACT_APP_API_FETCH_CATEGORY } from '../../redux/apiConstants';

function BusinessPageEdit() {
  let id;
  const [submiting, setSubmitting] = useState(false);
  const { dashboardStyle } = useContext(TableContext);
  // const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const params = useParams();
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Edit Business Page Details');
    id = params.id;

    const url2 = REACT_APP_API_FETCH_CATEGORY; // api url
    fetch(url2)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => {
        setCategory(resp.data.category);
      }); // setting response to state posts

    const actionData = {};
    actionData.actionUrl = `businesspage/${id}`;
    actionData.actionMethod = 'get';
    dispatch(Entry(actionData)).then((resp) => {
      // delete resp.payload.id;
      setData(resp.payload.data.businesspage);
      reset(resp.payload.data.businesspage);
    });
  }, []);

  // eslint-disable-next-line no-unused-vars
  function onSubmitdata(dataa) {
    if (dataa.name.trim().length === 0) {
      document.getElementById('name').focus();
    } else {
      setSubmitting(true);
      setError(null);
      const apiData = {
        name: dataa.name,
        category: dataa.category.value,
        description: dataa.description,
      };

      dataa.actionUrl = `businesspage/${params.id}`;
      dataa.actionMethod = 'patch';
      dataa.apiData = apiData;
      dispatch(Entry(dataa)).then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          navigate(-1);
          toast.success('Business Page updation successfull');
          // eslint-disable-next-line max-len
        } else if (
          resp.payload.code === 401 ||
          resp.payload.code === 400 ||
          resp.payload.code === 403
        ) {
          setError(resp.payload.message);
          toast.error('Error');
        } else {
          navigate(-1);
        }
      });
    }
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5 editdataform">
            {data.length !== 0 ? (
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    {data && (
                      <div>
                        <TextInput
                          className={styles.inputbox}
                          classNamedark={styles.inputbox1}
                          labelstyle={styles.formlabel}
                          label="Name"
                          fieldName="name"
                          placeHolder="Enter Name"
                          register={register}
                          errors={errors}
                          maximLength={100}
                          isRequired
                          mandatory
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div>
                      <SelectBox
                        className={styles.inputbox}
                        classNamedark={styles.inputbox1}
                        name="category"
                        label="Category"
                        labelstyle={styles.formlabel}
                        control={control}
                        register={register}
                        values={category}
                        errors={errors}
                        placeholder="Choose Category"
                        defaultvalue={data.category.id}
                        mandatory
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div>
                      <TextAreaField
                        className={styles.inputbox}
                        fieldName="description"
                        label="Overview"
                        register={register}
                        errors={errors}
                        placeHolder="Enter Overview"
                        isRequired
                        minimLength={10}
                      />
                    </div>
                  </div>
                </div>

                <br />
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Update'}
                />
                {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
              </form>
            ) : (
              <div style={{ textAlign: 'center', marginTop: '130px', color: '#39979d' }}>
                <SpinnerDotted />
              </div>
            )}
            <br />
            <ToastContainer autoClose={2000} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessPageEdit;
