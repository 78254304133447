import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SpinnerDotted } from 'spinners-react';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { Image } from 'react-bootstrap';
import { Entry } from '../../redux/entrySlice';
import styles from './edit.module.scss';
import { TableContext } from '../../contexts/tableContext';
// import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';
import 'react-toastify/dist/ReactToastify.css';
import TextInput from '../../components/TextField/TextField';
// import SingleFileUpload from '../../components/SingleFileUpload/SingleFileUpload';
import SingleFileUploadEdit from '../../components/SingleFileUploadEdit/SingleFileUploadEdit';

function SubjectCategoryEdit() {
  let id;
  const [submiting, setSubmitting] = useState(false);
  const { dashboardStyle } = useContext(TableContext);
  // const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const params = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const { files, setFiles, fileError, setFileError } = useContext(TableContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    setFiles([]);
    setFileError();
    window.scrollTo(0, 0);
    setDashboardHeader('Edit Subject Category Details');
    id = params.id;

    const actionData = {};
    actionData.actionUrl = `subjectcategory/${id}`;
    actionData.actionMethod = 'get';
    dispatch(Entry(actionData)).then((resp) => {
      // delete resp.payload.id;
      setData(resp.payload.data.subjectCategory);
      reset(resp.payload.data.subjectCategory);
    });
  }, []);

  // eslint-disable-next-line no-unused-vars
  function onSubmitdata(dataa) {
    if (dataa.name.trim().length === 0) {
      document.getElementById('name').focus();
    } else {
      setSubmitting(true);
      setError(null);
      const apiData = {
        name: dataa.name,
        ...(files.length > 0 && { File: files }),
      };

      dataa.actionUrl = `subjectcategory/${params.id}`;
      dataa.actionMethod = 'patch';
      dataa.apiData = apiData;
      dispatch(Entry(dataa)).then((resp) => {
        setSubmitting(false);
        setFiles([]);
        if (resp.payload.code === 200) {
          navigate(-1);
          toast.success('Subject Category updation successfull');
          // eslint-disable-next-line max-len
        } else if (
          resp.payload.code === 401 ||
          resp.payload.code === 400 ||
          resp.payload.code === 403
        ) {
          setError(resp.payload.message);
          toast.error('Error');
        } else {
          navigate(-1);
        }
      });
    }
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5 editdataform">
            {data.length !== 0 ? (
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    {data && (
                      <div>
                        <TextInput
                          className={styles.inputbox}
                          classNamedark={styles.inputbox1}
                          labelstyle={styles.formlabel}
                          label="Name"
                          fieldName="name"
                          placeHolder="Enter Name"
                          register={register}
                          errors={errors}
                          isRequired
                          mandatory
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className={styles.profilepicdiv}>
                      <p className={styles.title}>Image</p>
                      {files.length === 0 && (
                        <Image src={data.image} alt="Image" className={styles.categoryimage} />
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6 col-sm-6">
                    <SingleFileUploadEdit
                      editFiles={data.files}
                      section="subjectcategory"
                      mandatory
                    />
                    {fileError && (
                      <p
                        style={{
                          color: 'red',
                          marginTop: '5px',
                          fontSize: '14px',
                          fontFamily: 'SFUIDisplayRegular',
                        }}
                      >
                        {' '}
                        {
                          // Shows if error exist
                          fileError
                        }{' '}
                      </p>
                    )}
                  </div>
                </div>
                <br />
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Update'}
                />
                {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
              </form>
            ) : (
              <div style={{ textAlign: 'center', marginTop: '130px', color: '#39979d' }}>
                <SpinnerDotted />
              </div>
            )}
            <br />
            <ToastContainer autoClose={2000} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubjectCategoryEdit;
