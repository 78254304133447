import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import TextInput from '../../components/TextField/TextField';
import { Entry } from '../../redux/entrySlice';
import { TableContext } from '../../contexts/tableContext';
import styles from './createBusinessPage.module.scss';
import SelectBox from '../../components/SelectBox/SelectBox';
import TextAreaField from '../../components/TextArea/TextArea';
import { REACT_APP_API_FETCH_CATEGORY } from '../../redux/apiConstants';

function CreateBusinessPage() {
  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const userId = '64d3758eb06573294fdf453d';
  //   const [perror, setPerror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Add Business Page Details');
    const url2 = REACT_APP_API_FETCH_CATEGORY; // api url
    fetch(url2)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => {
        setCategory(resp.data.category);
      }); // setting response to state posts
  }, []);
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function onSubmitdata(data) {
    setSubmitting(true);
    setError(null);
    const apiData = {
      name: data.name,
      category: data.category.value,
      description: data.description,
      isVerified: 1,
      isPublished: true,
      user: userId,
      adminCreated: true,
    };
    data.actionUrl = 'businesspage';
    data.actionMethod = 'post';
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('Business Page creation successfull');
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError('Something went wrong!');
      }
    });
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
              //   toast.success('Subject Category creation successfull');
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Name"
                    register={register}
                    errors={errors}
                    maximLength={100}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="category"
                    label="Category"
                    values={category}
                    labelstyle={styles.formlabel}
                    control={control}
                    placeholder="Choose Category"
                    register={register}
                    errors={errors}
                    mandatory
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextAreaField
                    className={styles.inputbox}
                    fieldName="description"
                    label="Overview"
                    register={register}
                    errors={errors}
                    placeHolder="Enter Overview"
                    isRequired
                    minimLength={10}
                  />
                </div>
              </div>
              <br />
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Submit'}
                />
                <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                />
              </div>
              <br />
              {error && (
                <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                  {error}
                </h6>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateBusinessPage;
