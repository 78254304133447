/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { getCookies } from '../../hooks/useCookies';
import {
  REACT_APP_API_APPROVE_FTD_CLAIM_REQUESTS,
  REACT_APP_API_REJECT_FTD_CLAIM_REQUESTS,
  REACT_APP_API_REJECT_FTD_PUBLISH,
} from '../../redux/apiConstants';
import { TableContext } from '../../contexts/tableContext';
import TextAreaField from '../TextArea/TextArea';

function ClaimApprove(data) {
  console.log(data);
  const [canPublish, setCanPublish] = useState(true);
  const [ftdId, setFtdId] = useState();
  const { setApprove } = useContext(TableContext);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const approveFtd = (params) => {
    const encodeText = params.data.id;
    confirmAlert({
      title: canPublish ? '' : 'Oops!!',
      message: 'Are you sure you want to Approve',
      buttons: canPublish
        ? [
            {
              label: 'yes',
              onClick: async () => {
                const approveUrl = `${REACT_APP_API_APPROVE_FTD_CLAIM_REQUESTS}/${encodeText}`;
                try {
                  const headers = {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${getCookies('Token')}`,
                  };
                  const approve = await axios.patch(approveUrl, { headers });
                  toast.success('Approved successfully');
                  setApprove(`data last Approved is ${encodeText}`);
                } catch (error) {
                  console.log(error);
                }
              },
            },
            {
              label: 'No',
              // onClick: () => alert("Click No")
            },
          ]
        : [
            {
              label: 'Ok',
              // onClick: () => alert("Click No")
            },
          ],
    });
  };

  const rejectFtd = async (params) => {
    const encodeText = params.data.id;
    confirmAlert({
      title: canPublish ? '' : 'Oops!!',
      message: 'Are you sure you want to Reject',
      buttons: canPublish
        ? [
            {
              label: 'yes',
              onClick: async () => {
                const approveUrl = `${REACT_APP_API_REJECT_FTD_CLAIM_REQUESTS}/${encodeText}`;
                try {
                  const headers = {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${getCookies('Token')}`,
                  };
                  const reject = await axios.patch(approveUrl, { headers });
                  toast.success('Rejected successfully');
                  setApprove(`data last Rejected is ${encodeText}`);
                } catch (error) {
                  console.log(error);
                }
              },
            },
            {
              label: 'No',
              // onClick: () => alert("Click No")
            },
          ]
        : [
            {
              label: 'Ok',
              // onClick: () => alert("Click No")
            },
          ],
    });
  };
  return (
    <div>
      {data.data.status === 1 ? (
        <p> accepted</p>
      ) : data.data.status === 2 ? (
        <p>rejected</p>
      ) : (
        <ButtonGroup aria-label="Basic example">
          <Button onClick={() => approveFtd(data)} variant="primary">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
          <Button onClick={() => rejectFtd(data)} variant="primary">
            Reject
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
}

export default ClaimApprove;
