/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getCookies } from '../../hooks/useCookies';
import {
  REACT_APP_API_APPROVE_FTD_PUBLISH,
  REACT_APP_API_REJECT_FTD_PUBLISH,
} from '../../redux/apiConstants';
import { TableContext } from '../../contexts/tableContext';
import TextAreaField from '../TextArea/TextArea';

function Approve(data) {
  const [canPublish, setCanPublish] = useState(true);
  const [ftdId, setFtdId] = useState();
  const { setApprove } = useContext(TableContext);
  const [show, setShow] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const handleClose = () => {
    setValue('rejectResponse', null);
    reset();
    setShow(false);
  };
  let id;
  const handleShow = (params) => {
    setShow(true);
    id = params.data.id;
    setFtdId(id);
  };
  const approveFtd = (params) => {
    const encodeText = params.data.id;
    confirmAlert({
      title: canPublish ? '' : 'Oops!!',
      message: 'Are you sure you want to Approve',
      buttons: canPublish
        ? [
            {
              label: 'yes',
              onClick: async () => {
                const approveUrl = `${REACT_APP_API_APPROVE_FTD_PUBLISH}/${encodeText}`;
                try {
                  const headers = {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${getCookies('Token')}`,
                  };
                  const approve = await axios.patch(approveUrl, { headers });
                  toast.success('Approved successfully');
                  setApprove(`data last Approved is ${encodeText}`);
                } catch (error) {
                  console.log(error);
                }
              },
            },
            {
              label: 'No',
              // onClick: () => alert("Click No")
            },
          ]
        : [
            {
              label: 'Ok',
              // onClick: () => alert("Click No")
            },
          ],
    });
  };

  const rejectFtd = async (msg) => {
    const { rejectResponse } = msg;
    const res = {
      rejectedReason: rejectResponse,
      isVerified: 2,
    };
    const rejectUrl = `${REACT_APP_API_REJECT_FTD_PUBLISH}/${ftdId}`;
    try {
      const headers = {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${getCookies('Token')}`,
      };
      const approve = await axios.patch(rejectUrl, res, { headers });
      setShow(false);
      reset();
      toast.success('Rejected successfully');
      setApprove(`data last Rejected is ${id}`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <ButtonGroup aria-label="Basic example">
        <Button onClick={() => approveFtd(data)} variant="secondary">
          <FontAwesomeIcon icon="fa-solid fa-check" />
        </Button>
        <Button onClick={() => handleShow(data)} variant="secondary">
          Reject
        </Button>
      </ButtonGroup>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reject Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <TextAreaField
              className="textareaform"
              fieldName="rejectResponse"
              label="Response"
              register={register}
              errors={errors}
              placeHolder="Enter Reject Response"
              isRequired
              minimLength={10}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit(rejectFtd)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Approve;
