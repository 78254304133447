import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const FTD_DETAIL_LINK = `https://allfieldtrips.ca/auth`;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_FETCH_SUBJECT_CATEGORY = `${envValues.REACT_APP_API_ENDPOINT}/subjectcategory?`;
export const REACT_APP_API_FETCH_SUBSCRIPTION = `${envValues.REACT_APP_API_ENDPOINT}/subscription?`;
export const REACT_APP_API_FETCH_BUSINESS_USERS = `${envValues.REACT_APP_API_ENDPOINT}/appusers/businessusers?`;
export const REACT_APP_API_CHANGE_BUSINESS_USERS_STATUS = `${envValues.REACT_APP_API_ENDPOINT}/appusers/businessusers/toogle`;
export const REACT_APP_API_CHANGE_NORMAL_USERS_STATUS = `${envValues.REACT_APP_API_ENDPOINT}/appusers/normaluser/toogle`;
export const REACT_APP_API_FETCH_NORMAL_USERS = `${envValues.REACT_APP_API_ENDPOINT}/appusers/normaluser?`;
export const REACT_APP_API_FETCH_BUSINESS_PAGES = `${envValues.REACT_APP_API_ENDPOINT}/businesspage?populate=category&`;
export const REACT_APP_API_FETCH_CATEGORY = `${envValues.REACT_APP_API_ENDPOINT}/subjectcategory/categories/all`;
export const REACT_APP_API_FETCH_TRANSACTIONS = `${envValues.REACT_APP_API_ENDPOINT}/transactions?&populate=user,business&&`;
export const REACT_APP_API_FETCH_REVIEWS = `${envValues.REACT_APP_API_ENDPOINT}/reviews?&populate=userId,business&`;
export const REACT_APP_API_REVIEW_PUBLISH = `${envValues.REACT_APP_API_ENDPOINT}/reviews/publish`;
export const REACT_APP_API_FETCH_FTD_PUBLISH_REQUESTS = `${envValues.REACT_APP_API_ENDPOINT}/ftdrequests/publishrequests?populate=user,category&`;
export const REACT_APP_API_FETCH_FTD_CLAIM_REQUESTS = `${envValues.REACT_APP_API_ENDPOINT}/ftdrequests/claimrequests?populate=business,user&`;
export const REACT_APP_API_APPROVE_FTD_PUBLISH = `${envValues.REACT_APP_API_ENDPOINT}/ftdrequests/publishrequests/approve`;
export const REACT_APP_API_REJECT_FTD_PUBLISH = `${envValues.REACT_APP_API_ENDPOINT}/ftdrequests/publishrequests/reject`;
export const REACT_APP_API_APPROVE_FTD_CLAIM_REQUESTS = `${envValues.REACT_APP_API_ENDPOINT}/ftdrequests/claimrequests/approve`;
export const REACT_APP_API_REJECT_FTD_CLAIM_REQUESTS = `${envValues.REACT_APP_API_ENDPOINT}/ftdrequests/claimrequests/reject`;
export const REACT_APP_API_FETCH_USERS_COUNT = `${envValues.REACT_APP_API_ENDPOINT}/dashboard/users`;
export const REACT_APP_API_FETCH_SUPPORT = `${envValues.REACT_APP_API_ENDPOINT}/support?&populate=user&`;
export const REACT_APP_API_FETCH_REVENUE = `${envValues.REACT_APP_API_ENDPOINT}/dashboard/revenue`;
export const REACT_APP_API_FETCH_CONTACTSUPPORT = `${envValues.REACT_APP_API_ENDPOINT}/contactsupport?`;
export const REACT_APP_API_CHANGE_FTD_STATUS = `${envValues.REACT_APP_API_ENDPOINT}/businesspage/toogle`;
export const REACT_APP_API_FETCH_SUBSCRIBED_USERS_COUNT = `${envValues.REACT_APP_API_ENDPOINT}/dashboard/subscribedusers`;
