import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import TextInput from '../../components/TextField/TextField';
import { Entry } from '../../redux/entrySlice';
import { TableContext } from '../../contexts/tableContext';
import styles from './createSubscription.module.scss';
import SelectBox from '../../components/SelectBox/SelectBox';
import TextAreaField from '../../components/TextArea/TextArea';
import NumberInput from '../../components/NumberBox/NumberBox';

function CreateSubscription() {
  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const validities = [
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Yearly', label: 'Yearly' },
  ];
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  //   const [perror, setPerror] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Add Subscription Details');
  }, []);
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function onSubmitdata(data) {
    setSubmitting(true);
    setError(null);
    const apiData = {
      name: data.name,
      amount: data.amount,
      description: data.description,
      validity: data.validity.value,
    };
    data.actionUrl = 'subscription';
    data.actionMethod = 'post';
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('Subscription creation successfull');
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError('Something went wrong!');
      }
    });
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
              //   toast.success('Subject Category creation successfull');
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Amount"
                    fieldName="amount"
                    placeHolder="Enter Amount"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  /> */}

                  <NumberInput
                    className={styles.inputbox}
                    labelstyle={styles.formlabel}
                    label="Amount"
                    fieldName="amount"
                    register={register}
                    errors={errors}
                    isRequired
                    maximLength={10}
                    minimLength={2}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextAreaField
                    className={styles.inputbox}
                    labelstyle={styles.formlabel}
                    fieldName="description"
                    label="Description"
                    register={register}
                    errors={errors}
                    placeHolder="Enter Description"
                    isRequired
                    minimLength={10}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="validity"
                    label="Validity"
                    values={validities}
                    labelstyle={styles.formlabel}
                    control={control}
                    placeholder="Choose Validity"
                    register={register}
                    errors={errors}
                    mandatory
                  />
                </div>
              </div>
              <br />
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Submit'}
                />
                <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                />
              </div>
              <br />
              {error && (
                <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                  {error}
                </h6>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSubscription;
