/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import TextInput from '../../components/TextField/TextField';
import { Entry } from '../../redux/entrySlice';
// import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';
import { TableContext } from '../../contexts/tableContext';
import styles from './create.module.scss';
import SingleFileUpload from '../../components/SingleFileUpload/SingleFileUpload';

function CreateSubjectCategory() {
  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const navigate = useNavigate();
  const [imgError, setImgError] = useState(null);
  const [error, setError] = useState(null);
  //   const [perror, setPerror] = useState(null);
  const { setFiles, setUplodedImgs } = useContext(TableContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Add Subject Category Details');
  }, []);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')

      .trim()
      .min(3, 'Minimum 3 digits required')
      .max(15, 'Maximum 15 digits'),
    image: Yup.mixed()
      .test('isvalid', 'image is required', (files, context) => {
        if (files) {
          return true;
        } else {
          return false;
        }
      })
      .test('type', 'Only the following formats are accepted:  .svg and .svg+xml', (value) => {
        if (!value) return true;
        return value && (value[0].type === 'image/svg+xml' || value[0].type === 'image/svg');
      })
      .test('fileSize', 'The file is too large', (value) => {
        if (!value) return true; // Skip validation if no file is selected
        return value && value[0].size <= 10 * 1024 * 1024; // 10MB
      }),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm(formOptions);
  // useEffect(() => {
  //   if (files.length !== 0) {
  //     setImgError();
  //   }
  // }, [files]);

  const ResetData = (e) => {
    e.preventDefault();
    // setImgError();
    // setFileError();
    // setFiles([]);
    setUplodedImgs([]);
    reset();
  };
  function onSubmitdata(data) {
    console.log(data);
    // if (files.length === 0) {
    //   setImgError('please select image');
    //   setSubmitting(false);
    // } else {
    setSubmitting(true);
    setError(null);
    const apiData = {
      name: data.name,
      File: data.image,
    };
    data.actionUrl = 'subjectcategory';
    data.actionMethod = 'post';
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      setFiles([]);
      setUplodedImgs([]);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('Subject Category creation successfull');
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError('Something went wrong!');
      }
    });
    // }
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
              //   toast.success('Subject Category creation successfull');
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  /> */}
                  <div className="form-group">
                    <label htmlFor="name" className={styles.formlabel}>
                      name
                    </label>
                    <span style={{ color: 'red' }}>*</span> <span />
                    <input
                      className={styles.inputbox}
                      {...register('name')}
                      placeholder="Enter Name"
                    />
                    <p
                      style={{
                        color: 'red',
                        marginTop: '5px',
                        fontSize: '14px',
                        fontFamily: 'SFUIDisplayRegular',
                      }}
                    >
                      {' '}
                      {
                        // Shows if error exist
                        errors.name && errors.name.message
                      }{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <p style={{ color: 'gray' }}>Please add only .svg or .svg+xml format images.</p>
                  <Controller
                    control={control}
                    name="image"
                    render={({ field: { onChange }, formState }) => (
                      <SingleFileUpload onChange={onChange} mandatory />
                    )}
                  />

                  {errors.image && (
                    <p
                      style={{
                        color: 'red',
                        marginTop: '5px',
                        fontSize: '14px',
                        fontFamily: 'SFUIDisplayRegular',
                      }}
                    >
                      {' '}
                      {
                        // Shows if error exist
                        errors.image.message
                      }{' '}
                    </p>
                  )}
                  {/* {imgError && (
                    <p
                      style={{
                        color: 'red',
                        marginTop: '5px',
                        fontSize: '14px',
                        fontFamily: 'SFUIDisplayRegular',
                      }}
                    >
                      {' '}
                      {
                        // Shows if error exist
                        imgError
                      }{' '}
                    </p>
                  )} */}
                </div>
              </div>
              <br />
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Submit'}
                />
                <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={ResetData}
                />
              </div>
              <br />
              {error && (
                <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                  {error}
                </h6>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSubjectCategory;
