/* eslint-disable react/no-unescaped-entities */
/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { getCookies } from '../../../hooks/useCookies';
import { TableContext } from '../../../contexts/tableContext';
import styles from './view.module.scss';
import envValues from '../../../enviornment';
import { FTD_DETAIL_LINK } from '../../../redux/apiConstants';

const ViewBusinessUser = React.memo(() => {
  const navigate = useNavigate();
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  let userid;
  const params = useParams();
  const token = `Bearer ${getCookies('Token')}`;
  // const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({ loading: true, data: [] });
  const [ftds, setFtds] = useState([]);
  let statusCode;
  const getUserDetails = async () => {
    // setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/appusers/businessusers/view/${userid}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails({ loading: false, data: res.data.data.user });
          setFtds(res.data.data.ftds);
          // setLoading(false);
        } else {
          // setLoading(false);
          setDetails({ loading: false });
        }
      })
      .catch((err) => {
        setDetails({ loading: false });
        return err;
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    userid = params.userid;
    getUserDetails();
    setDashboardHeader('Business User Details');
  }, []);
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.empdetailssection}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          {details.loading ? (
            <SpinnerDotted
              style={{
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
                color: '#39979d',
              }}
            />
          ) : (
            <Container fluid>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Name</span>
                          <p className={styles.empname}>{details.data.name}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Email</span>
                          <p className={styles.empname}>{details.data.email}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Phone Number</span>
                          <p className={styles.empname}>
                            {details.data.phone ? details.data.phone : 'N/A'}
                          </p>
                        </div>
                      </Col>
                      {/* <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Company Name</span>
                          <p className={styles.empname}>
                            {details.data.companyname ? details.data.companyname : 'N/A'}
                          </p>
                        </div>
                      </Col> */}
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Address</span>
                          <p className={styles.empname}>
                            {details.data.address ? details.data.address : 'N/A'} ,
                            {details.data.zip ? details.data.zip : ''}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Job Role</span>
                          <p className={styles.empname}>
                            {details.data.jobrole ? details.data.jobrole : 'N/A'}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Status</span>
                          <p className={styles.empname}>
                            {details.data.active ? 'Active' : 'InActive'}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Subscriptions</span>

                          {ftds.length ? (
                            ftds?.map((business) =>
                              business.subscription_id ? (
                                <p className={styles.empname}>
                                  {business.name}: {business.subscription_id.name}
                                </p>
                              ) : (
                                <p className={styles.empname}>{business.name}: Not Subscribed</p>
                              )
                            )
                          ) : (
                            <p className={styles.empname}>N/A</p>
                          )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <a
                            target="_blank"
                            href={`${FTD_DETAIL_LINK}/adminlogin?token=${getCookies(
                              'refreshToken'
                            )}&uid=${details.data.id}&utp=business`}
                            rel="noreferrer"
                            className={styles.title}
                          >
                            View Business User Profile
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
});

export default ViewBusinessUser;
