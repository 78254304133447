/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styles from './index.module.scss';
import CustomTable from '../../../components/CustomTable/CustomTable';
import {
  REACT_APP_API_CHANGE_BUSINESS_USERS_STATUS,
  REACT_APP_API_FETCH_BUSINESS_USERS,
} from '../../../redux/apiConstants';
import { TableContext } from '../../../contexts/tableContext';

const BusinessUserIndex = React.memo(() => {
  const { setDashboardHeader } = useContext(TableContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Business Users');
  }, []);
  return (
    <div className={styles.indexdiv}>
      <CustomTable
        urlParam={REACT_APP_API_FETCH_BUSINESS_USERS}
        section="businessusers"
        toggleButton={{
          show: true,
          field: 'active',
          url: `${REACT_APP_API_CHANGE_BUSINESS_USERS_STATUS}`,
        }}
        // deleteCondition={{ checkCondition: false, apiUrl: 'www.google.com' }}
      />
    </div>
  );
});
export default BusinessUserIndex;
