import axios from 'axios';
import React, { useEffect, useContext, useState } from 'react';
import { SpinnerDotted } from 'spinners-react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { TableContext } from '../../contexts/tableContext';
import { REACT_APP_API_FETCH_TRANSACTIONS } from '../../redux/apiConstants';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styles from './index.module.scss';
import { getCookies } from '../../hooks/useCookies';
import CustomTable from '../../components/CustomTable/CustomTable';
import DateRangeFilter from '../../components/DateRangeFilter/DateRangeFilter';

export default function TransactionIndex() {
  let response;
  let url;
  const token = `Bearer ${getCookies('Token')}`;
  const { setDashboardHeader, dashboardStyle } = useContext(TableContext);
  const { dateRanges, setdateRanges } = useContext(TableContext);
  let statusCode;
  const [data, setData] = useState([]);
  const { setTableLoading, pageNum, sorting, search, deletedData } = useContext(TableContext);
  const dataPerPage = 5;
  const [open, setOpen] = useState(false);

  const getTransactions = async () => {
    setTableLoading(true);
    url = REACT_APP_API_FETCH_TRANSACTIONS;
    url += `page=${pageNum}&limit=${dataPerPage}`;
    if (sorting.sortType && sorting.sortType !== 'none') {
      url += `&sortBy=${sorting.col}:${sorting.sortType}`;
    }
    if (search !== null) {
      url += `&searchBy=${search}`;
    }
    if (dateRanges !== null) {
      url += `&daterange=${encodeURIComponent(dateRanges)}`;
    }
    axios
      .get(url, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        setTableLoading(false);
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        if (statusCode === 200) {
          response = res.data.data.result;
          setData(response);
        }
      })
      .catch((err) => {
        setTableLoading(false);
        return err;
      });
  };
  // date filter
  const onChange = (ranges) => {
    if (
      moment(ranges.startDate).format('MM-DD-YYYY') !== moment(ranges.endDate).format('MM-DD-YYYY')
    ) {
      setOpen(false);
    } else if (ranges.startDate === '' && ranges.endDate === '') {
      setOpen(false);
    }
    const dateRange = JSON.stringify(ranges);
    setdateRanges(dateRange);

    setTableLoading(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Transactions');
    getTransactions();
  }, [search, sorting, pageNum, deletedData, dateRanges]);
  const handleRefresh = () => {
    setdateRanges(null);
  };
  return (
    <div>
      <div
        className={styles[dashboardStyle]}
        style={{ paddingBottom: '0px' }}
        id={styles.dashboardcont3}
      >
        <div className={styles.indexdiv}>
          <Row>
            <Col
              lg={6}
              md={6}
              style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
            >
              <DateRangeFilter onChange={onChange} open={open} setOpen={setOpen} />
              {dateRanges !== null && (
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={() => handleRefresh()}
                  style={{ marginLeft: '10px', marginTop: '5px', color: '#39979d' }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              )}
            </Col>
          </Row>
        </div>
      </div>
      {Object.keys(data).length > 0 ? (
        <CustomTable
          urlParam={REACT_APP_API_FETCH_TRANSACTIONS}
          section="transactions"
          toggleButton={{ show: true, field: 'active' }}
          deleteCondition={{ checkCondition: false, apiUrl: 'www.google.com' }}
        />
      ) : (
        <SpinnerDotted
          style={{
            color: '#39979d',
            left: '50%',
            position: 'relative',
            textAlign: 'center',
            top: '50%',
            minHeight: '100vh',
          }}
        />
      )}
    </div>
  );
}
